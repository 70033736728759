import { Switch, Route } from 'react-router-dom'
import Nav from './Nav';
import Home from './Pages/Home/Home';
import Projects from './Pages/Projects/Projects';
import Contact from './Pages/Contact/Contact'
function App() {


  return (
    <div className="mt-[8svh] font-serif bg-[#f7f7f7] min-h-[92svh]">

      <Nav />

      <Switch>

        <Route path='/projects/:id?'>
          <Projects />
        </Route>

        <Route path='/contact/:subject?'>
          <Contact />
        </Route>

        <Route path='/'>
          <Home />
        </Route>


      </Switch>


    </div>
  );
}

export default App;
