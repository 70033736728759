import { useHistory } from "react-router-dom/cjs/react-router-dom.min";
import { useState } from 'react'

function ProjectCard({project}) {

    const history = useHistory()
    const renderTechs = project.techs.map( t =>{
        return <div className='p-1 border border-black rounded text-white bg-black hover:animate-wiggle-it hover:cursor-default' key={t}>{t}</div>
    })

    const [ isLoaded, setIsLoaded ] = useState(false)

    return ( 

        <div  id={project.id}  
            className={`flex flex-col gap-4 w-full py-[10svh]`}>

            <div className='overflow-hidden'>
                <h1 className='font-bold text-3xl animate-glide-in-bottom-200'>{project.title}</h1>
            </div>

            <div className='aspect-video w-[100%] h-auto relative '>
                <div className={`${isLoaded && 'hidden'} absolute top-0 h-full w-full bg-[#a0a0a0] animate-pulse`}></div>
                <img onLoad={()=>setIsLoaded(true)} className={`object-cover border h-full w-full border-black opacity-0 ${isLoaded && 'opacity-100'} transform-opacity duration-[0.5s] `} alt='proj' src={project.image} />
            </div>

            <div className='p-4 border-l border-black overflow-hidden'>
                <p className=' animate-glide-in-bottom-500'>{project.description}</p>
            </div>

            
            <div className='text-[12px] flex flex-wrap gap-2 overflow-hidden' >{renderTechs}</div>
            
            <div className='flex place-content-end gap-2 text-sm underline px-4'>
                <a className='font-extralight' href={project.demo} rel="noreferrer" target='_blank'>View site</a>
                <a className='font-extralight' href={project.git} rel="noreferrer" target='_blank'>Github</a>
            </div>

            <div className='overflow-hidden'>
                <p className='text-left text-sm mt-[5svh]'>comments or suggestions? let me know <button onClick={()=>history.push(`/contact/${project.title}`)} className='underline'>here</button></p>
            </div>
    
        </div> );
}

export default ProjectCard;