import { NavLink } from "react-router-dom";
import { useState } from 'react'
import menu from './Assets/icons/icons8-hamburger-menu-100.png'


function Nav() {

    const [ isOpen, setIsOpen ] = useState(false)

    function toggle(){
        setIsOpen(!isOpen)
    }

    const renderNavLinks = [ 'projects', 'contact' ].map( n => {
        return <NavLink key={n} onClick={toggle} activeClassName='activeNavItem' className='navItem' to={`/${n}`}>{n}</NavLink>
               
    })

    return ( 

        <div className={`navContainer z-50`}>


            <div className='flex justify-between w-full sm:w-1/2'>

                <NavLink to='/' onClick={()=>setIsOpen(false)}>
                    <h1 className='logo animate-glide-in-left'>LV.</h1>
                </NavLink>

                <button onClick={toggle} className=' sm:hidden '>
                    <img className='h-[40px]' alt='menu' src={menu} />
                </button>

            </div>

            <div className={`w-full divide-y divide-slate-200 overflow-hidden transform-h duration-300 ${isOpen ? 'max-h-[100svh]' : 'max-h-0' } sm:flex sm:flex-row sm:w-1/2 sm:max-h-fit sm:divide-none justify-around text-center sm:animate-glide-in-right`} >

                { 
                    [ 
                        <NavLink key='home' onClick={toggle} activeClassName='activeNavItem' className='navItem' exact to={'/'}>home</NavLink>,
                        ...renderNavLinks
                    ]
                }
                
            </div> 

        </div>
    
    
    );
}

export default Nav;