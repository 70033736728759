import ProjectCard from "./ProjectCard";
import { useEffect } from "react";
import { useParams } from 'react-router-dom'
import { projects } from '../../Data'

function Projects() {


    const { id } = useParams()

    const projectList = projects.map( p => {
        return <button onClick={()=>scrollToSection(p.id)} key={p.id} className='p-2 underline text-left font-extralight text-xs sm:text-sm uppercase'>{p.title}</button>
    })

    const renderProjects = projects.map( p => {
        return <ProjectCard key={p.id} project={p} />
    })

    function scrollToSection(id){
        const section = document.getElementById(id);
        section.scrollIntoView({ behavior: 'smooth', block: 'center' });
    }

    useEffect(()=>{
        if(id) scrollToSection(id)
    },[id])
    
    return ( 

        <div className='min-h-[92svh] max-w-[1200px] m-auto animate-fade-in'>

            <div className='hidden sm:block fixed top-[8svh] border-b border-r border-black p-4 h-fit w-1/5 max-w-fit animate-glide-in-left'>

                <h1 className='sm:text-lg font-semibold'>PROJECTS</h1>

                <div className='p-2 flex flex-col'>
                    {projectList}
                </div>

            </div>

            <div className='divide-y divide-black pb-[19svh] sm:w-4/5 sm:ml-auto px-[4vw]'>
                {renderProjects}
            </div>


        </div>
    
    
    )
}

export default Projects;