

import rails from './Assets/update_logos/rails/rails_white_400.png'
import react from './Assets/update_logos/react/react_white_400.png'
import ruby from './Assets/update_logos/ruby/ruby_white-400.png'
import javascript from './Assets/update_logos/javascript/javascript_white_400.png'
import redux from './Assets/update_logos/redux/redux_white_400.png'
import tailwind from './Assets/update_logos/tailwind/tailwindcss_white_400.png'
import cloudinary from './Assets/update_logos/cloudinary/cloudinary_white_400.png'
import mapbox from './Assets/update_logos/mapbox/mapbox_white_400.png'
import css from './Assets/update_logos/CSS.png'
import html from './Assets/update_logos/html.png'
import bootstrap from './Assets/update_logos/icons8-bootstrap-100.png'
import jquery from './Assets/update_logos/icons8-jquery-100.png'
import sinatra from './Assets/update_logos/icons8-s-100.png'
import bcrypt from './Assets/update_logos/icons8-b-100.png'
import http from './Assets/update_logos/icons8-h-100.png'
import geocoder from './Assets/update_logos/icons8-g-100.png'
import active from './Assets/update_logos/icons8-a-100.png'

import birdIcon from './Assets/icons/proj/icons8-binocular-100.png'
import appleIcon from './Assets/icons/proj/icons8-apple-100.png'
import jobIcon from './Assets/icons/proj/icons8-job-100.png'
import dogIcon from './Assets/icons/proj/icons8-dog-100.png'

import inverseBird from './Assets/icons/proj/icons8-binocular-inverse-100.png'
import inverseApple from './Assets/icons/proj/icons8-apple-inverse-100.png'
import inverseJob from './Assets/icons/proj/icons8-job-inverse-100-.png'
import inverseDog from './Assets/icons/proj/icons8-dog-inverse-100.png'


import fm from './Assets/images/fm.png'
import bw from './Assets/images/bw.png'
import ff from './Assets/images/ff.png'
import jb from './Assets/images/jb.png'


const projects = [
    {
        id: 0,
        icon: birdIcon,
        icon2: inverseBird,
        size: 'a',
        image: bw,
        tile_color:'34495D',
        title: 'Bird Watcher',
        vid_url:'https://res.cloudinary.com/dfbe9u9zm/video/upload/v1696712820/bwVid_y3zkoc.mov',
        images:[],
        description: 'Bird Watcher is a full stack, gps based, diary web app for bird enthusiasts looking for a better way to document their sightings.  It features a map function, which plots user bird sightings, providing a visual representation of your birdwatching experiences.',
        techs: [ 'React.js', 'Redux.js', 'Javascript', 'Rails', 'Ruby', 'Tailwind CSS', 'Cloudinary API', 'Mapbox API', 'Geocoder API' ],
        git: 'https://github.com/lvas248/b_watcher',
        demo: 'https://b-watcher.onrender.com'
    },

    {
        id: 1,
        icon: appleIcon,
        icon2: inverseApple,
        size: 'b',
        image: fm,
        tile_color:'EE7738',
        title: "Farmer's Market",
        vid_url: 'https://res.cloudinary.com/dfbe9u9zm/video/upload/v1696630273/Screen_Recording_2023-10-06_at_5.18.16_PM_uoqvaa.mov',
        images:[],
        description: 'Farmers Market is a complete, full-stack, e-commerce web application, providing a lifelike online shopping experience for fresh produce.',
        techs: ['Javascript', 'React.js', 'Redux.js',  'Ruby', 'Rails', 'Active Record', 'HTML', 'TailwindsCSS', 'Geoapify'],
        git: 'https://github.com/lvas248/farmers_market_revamp',
        demo: 'https://farmers-market-c6jl.onrender.com'

    },

    {
        id: 2,
        icon: jobIcon,
        icon2: inverseJob,
        size: 'b',
        image: jb,
        tile_color:'F59D2A',
        title: 'NYC Job Hunt',
        vid_url: 'https://res.cloudinary.com/dfbe9u9zm/video/upload/v1696712166/jobsvid_vz8gqv.mov',
        images: [],
        description: 'NYC Job Hunt is a React-based application offering users a streamlined platform to explore, save, and manage NYC government job listings.',
        techs: ['React.js', 'Rails', 'Redux', 'TailwindCSS', 'Active Record' ],
        git: 'https://github.com/lvas248/jobs',
        demo: 'https://jobs-b8os.onrender.com'            
    },
    {
        id:3,
        icon: dogIcon,
        icon2: inverseDog,
        size: 'a',
        image: ff,
        tile_color:'2C3D4F',
        title: 'Fetch Finder',
        vid_url: 'https://res.cloudinary.com/dfbe9u9zm/video/upload/v1696711457/ffVid_wkriid.mov',
        images: [],
        description: 'Fetch Finder is a GPS-based web app that helps NYC dog owners find nearby dog parks with ratings, feedback, and real-time occupancy data, ensuring enjoyable outings for their furry friends.',
        techs: ['React.js', 'Rails', 'Redux', 'Reactstrap', 'Mapbox' ],
        git: 'https://github.com/lvas248/FetchFinder',
        demo: 'https://fetchfinder.onrender.com'            
    }
]

const skills = [
    
    {id: 11, name: 'React.js', logo: react, type: 'Frontend'},
    {id: 12, name: 'Javascript', logo: javascript, type:'Frontend' },
    {id: 13, name: 'Redux.js', logo: redux, type: 'Frontend'},
    {id: 14, name: null, logo: rails, type: 'Backend'},
    {id: 15, name: 'Ruby', logo: ruby, type:'Backend'},
    {id: 16, name: 'Tailwind', logo: tailwind, type: 'Frontend'},
    {id: 17, name: null, logo: cloudinary, type:'other'},
    {id: 18, name: null, logo: mapbox, type: 'other'},
    {id: 19, name: 'HTML', logo: html, type: 'Frontend'},
    {id: 20, name: 'CSS', logo: css, type: 'Frontend'},
    {id: 21, name: 'Bootsrap', logo: bootstrap, type: 'Frontend'},
    {id: 22, name: 'JQuery', logo: jquery, type: 'Frontend'},
    {id: 23, name: 'Active Record', logo: active, type: 'Backend'},
    {id: 24, name: 'Sinatra', logo: sinatra, type: 'Backend'},
    {id: 25, name: 'BCrypt', logo: bcrypt, type: 'Backend'},
    {id: 26, name: 'Httparty', logo: http, type: 'other'},
    {id: 27, name: 'Geocoder', logo: geocoder, type: 'other'},





]

export { rails, react, ruby, javascript, redux, tailwind, cloudinary, mapbox, css, html, skills, projects }