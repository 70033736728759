import { useHistory } from 'react-router-dom'
import { projects, skills } from '../../Data'
import github from '../../Assets/icons/icons8-github-100.png'
import linkedIn from '../../Assets/icons/icons8-linkedin-100.png'
import medium from '../../Assets/icons/icons8-medium-100.png'

function Home() {


    const history = useHistory()

    function renderSkills(type){
        return skills.map( s =>{
            if(s.type === type) return  <div key={s.id} className={`techIconContainer ${ !s.name && 'h-[90px] aspect-video w-fit'} `}>
                                            <img className='techIcon ' alt='skill' src={s.logo} />
                                            <p className={`${!s.name && 'hidden'}`}>{s.name}</p>
                                        </div>
            else return null
        })
    }

    const renderProjs = projects.map( p =>{
        return <button onClick={()=>history.push(`/projects/${p.id}`)} key={p.id} className='group grid h-[120px] w-fit place-self-center'>
                    <div className=' h-[100px] aspect-square border-4 border-black rounded-2xl p-2 group-hover:bg-black' >
                        <img className='h-full w-full group-hover:hidden' alt='icon' src={p.icon} /> 
                        <img className='h-full w-full hidden group-hover:block' alt='icon' src={p.icon2} /> 
                    </div>
                    <p className='text-[10px] font-bold sm:hidden group-hover:block group-hover:sm:animate-fade-in '>{p.title}</p>
                </button>
    })


    return ( 
        <div className='relative min-h-[92svh] flex flex-col animate-fade-in pb-[10svh]'>

            <div id='hero' 
                className='z-10 min-h-[70svh] pt-[8svh] px-[5vw] w-[80vw] mx-auto'>

                <div className='flex place-content-end py-[4svh] gap-4 animate-glide-in-right'>

                    <a href='https://github.com/lvas248'>
                        <img className='h-[30px] hover:sm:animate-scale-large' alt='git' src={github}/>
                    </a>

                    <a href='https://www.linkedin.com/in/lvas248/'>
                        <img className='h-[30px] hover:sm:animate-scale-large' alt='linkedin' src={linkedIn}/>
                    </a>

                    <a href='https://medium.com/@lvas248'>
                        <img className='h-[30px] hover:sm:animate-scale-large' alt='medium' src={medium}/>
                    </a>

                </div>

                <div className='flex gap-5 overflow-Y-hidden'>
                    <p className=' lowercase font-bold text-[50px] sm:text-[60px] md:text-[80px] animate-glide-in-bottom-200'>Luis </p>
                    <p className='lowercase font-bold text-[50px] sm:text-[60px] md:text-[80px] animate-glide-in-bottom-200'>Vasquez</p>
                </div>
  
                <div className='border-l-8 border-black pl-8 leading-tight '>
                    <div className='overflow-hidden'>
                        <h1 className=' w-full text-[20px] sm:text-[40px] md:text-[50px] leading-tight lowercase animate-glide-in-bottom-500'>Full Stack SOFTWARE ENGINEER</h1>
                    </div>

                    <div className='overflow-hidden'>
                        <p className='text-[12px] sm:text-[20px] font-extraligh py-[2svh] oveflow-hidden animate-glide-in-bottom-750'>I construct end-to-end web applications that are dependable, scalable, and prioritize an exceptional user experience.</p>
                    </div>
                </div>

                <div className='w-full'>
                    <button onClick={()=>history.push('/contact')} className='float-right text-center border-2 border-black rounded-xl sm:text-xl p-2 bg-[#f7f7f7] text-black font-bold hover:bg-black hover:text-white'>get in touch</button>
                </div>

            </div>

            <div id='proj'
                className='min-h-[20svh] pt-[8svh] px-[5vw] w-[80vw] mx-auto '
            >
                <h1 className='text-[30px] font-bold'>Projects</h1>

                <div className='my-10 grid grid-cols-2 sm:grid-cols-4 gap-10 min-h-[225px] '>{renderProjs}</div>

            </div>

            <div id='skills' 
                className='min-h-[55svh] pt-[8svh] px-[5vw] w-[80vw] mx-auto flex flex-col gap-10'>
                
                <h1 className='text-[30px] font-bold'>Skills</h1>
                
                <div className='p-2'>

                    <div className=''>

                        <h3 className='text-xl font-semibold'>Frontend Development</h3>

                        <p className='p-4'> I enjoy building clean and user-friendly web apps with a focus on creating simple yet effective interfaces that enhance the user experience.</p>

                    </div>
                    
                    <div className='px-4 flex flex-wrap gap-2'>

                        { renderSkills('Frontend') }

                    </div>

                </div>

                <div className='p-2'>


                    <div className=''>

                        <h3 className='text-xl font-semibold'>Backend Development</h3>

                        <p className='p-4'>I specialize in crafting efficient server-side functionality that contributes to the smooth performance of my projects. My focus is on ensuring reliability and scalability, meeting high standards for seamless backend experiences.</p>

                    </div>
                    
                    <div className='px-4 flex flex-wrap gap-2'>

                        { renderSkills('Backend') }
                        
                    </div>

                </div>

                <div className='p-2'>

                    <div className=''>

                        <h3 className='text-xl font-semibold'>More</h3>

                        <p className='p-4'>I seamlessly incorporate third party APIs, expanding the capabilities of web apps for optimal performace.</p>

                    </div>

                    <div className='px-2 flex flex-wrap gap-2'>

                        { renderSkills('other') }

                    </div>

                </div>
             
            </div>

        </div>
     );
}

export default Home;