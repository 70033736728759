import EmailSender from "./EmailSender";
import phone from '../../Assets/icons/icons8-phone-100.png'
import email from '../../Assets/icons/icons8-email-100.png'
import pin from '../../Assets/icons/icons8-region-100.png'


function Contact() {
    return ( 
        <div id='contact'
        className='relative min-h-[92svh] m-auto animate-fade-in '
        >
            <div className='flex flex-col gap-[5vh] py-[5vh] max-w-[1050px] mx-auto'>

                <div className='contactItemsContainer flex-col sm:flex-row font-bold overflow-hidden'
                >
                    <div className='contactInfoItem '>
                        <img className='contactIcon' alt='phone' src={phone}/>
                        <p className=''>845 709 0116</p>
                    </div>

                    <div className='contactInfoItem'>
                        <img className='contactIcon' alt='email' src={email}/>
                        <p className=''>LVAS248@GMAIL.COM</p>
                    </div>

                    <div className='contactInfoItem'>
                        <img className='contactIcon' alt='pin' src={pin}/>
                        <p className=''>NEW YORK, NY</p>
                    </div>
                </div>

                <div className='overflow-hidden'>
                    <EmailSender />

                </div>
                

            </div>
        </div> 
    );
}

export default Contact;